@each $current-alpha in $alpha-list {
    $i: index($alpha-list, $current-alpha);

    .alpha-#{$current-alpha} {
        .article__tile__category {
            background-color: nth($alpha-color-list, $i);
        }
    }
    .article__view__category.alpha-#{$current-alpha}{
        background-color: nth($alpha-color-list, $i);
        &:hover{
            background-color: darken((nth($alpha-color-list, $i)), 10);
        }
    }
}