.navBar__scrollArea {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 170px);
  background-color: #ee1f3b;
  @include media-breakpoint-up(lg) {
    max-height: calc(100vh - 190px);

    // .scrolled &, .subpage & {
    //   max-height: calc(100vh - 150px);
    // }
  }
}

