// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova",sans-serif;
$family-header: "bree-serif",serif;

// font-size
$font-size-desktop: 18px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-normal: $weight-regular;
$weight-bold: 700;
$weight-exbold: 800;

// colors - sister colours from bootrap
$color-blue:    #007bff;
$color-red:     #ee1f3b;
$color-orange:  #fd7e14;
$color-green:   #28a745;

// more colors
$color-gold:  #faa222;
$color-dark-gray: #252525;
$color-mid-blue: #5c6594;

//new colors
$color-dark-blue: #00004c;

// site colors
$primary-color: $color-red;
$font-color: #414042;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color:#ebebeb;
$link-color: $primary-color;
$link-hover-color: darken($primary-color, 10);

// top-bar
//$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 1.6rem, desktop: 2rem);
$top-bar-border: (mobile: 5px, desktop: 12px);
//$top-bar-height: (mobile: 2.7rem, small-device:2.8rem, tablet:3.5rem, desktop: 3.1rem);
$top-bar-height: (mobile: 44px, small-device:56px, tablet:56px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;

$alpha-list: a b c d e f g h i j k l m n o p q r s t u v w x y z;
$alpha-color-list: #ba2452 #d61c51 #d61a38 #e61449 #f03f47 #e84023 #f37032 #f7941e #fdb913 #cacf3c #99ca45 #70bf54 #00b07d #068a50 #007d74 #00a5ab #00a5cf #0098b3 #0077c0 #17568b #163d63 #3a4282 #482c75 #68429d #8548a1 #ab3a96 ;