.navBar__subMenu {
  transition: all 0.4s;
  max-height: 0;
  overflow: hidden;
  display: list-item;
}

.navBar__subItem:nth-last-child(1) {
  margin-bottom: 0;
}

a.navBar__subLink {
  //padding-left: 2.5rem;
  //margin-left: 1rem;
  border-bottom: none;
  background-color: rgba(0,0,0,.2);
  padding-left: 1rem;
  font-weight: 400;
  @include media-breakpoint-up(lg) {
    //padding-left: 3.5rem;
    //margin-left: 2rem;
  }
  position: relative;
  &:hover{
    text-decoration: none;
    color: #f7941e;
  }
  // &:before {
  //   content: '• ';
  //   position: absolute;
  //   left: 1.5rem;
  //   @include media-breakpoint-up(lg) {
  //     left: 2rem;
  //   }
  // }
}